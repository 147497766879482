import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import AdvisorImg from "../../../images/avatar.png";
import "./Advisors.scss"
// markup
const Advisors = (props) => {


  return (
    <React.Fragment>
      <div className="advisors-block components--AreaGuideDetails--Advisors--Advisors">
        <Container>
          <Row>
            <Col className="d-lg-flex">
              {props.Intro_Content &&
                <div className="advisors-text">
                  {parse(props.Intro_Content)}
                </div>
              }
              {props.People ?
                <div className="advisor-info">
                  <div className="advisor-img">
                    <img src={props.People.Image.url} alt={props.People.Image.alternativeText} />
                  </div>
                  <div className="advisor-contact">
                    <span className="advisor-name">{props.People.Name}</span>
                    <span className="advisor-designation">{props.People.Designation}</span>
                    <a href={`tel:${props.People.Phone}`} className="phone-number">
                      <i className="icon-phone"></i>
                      <span>{props.People.Phone}</span>
                    </a>
                  </div>
                </div> :
                <div className="advisor-info">
                  <div className="info">
                    <div className="advisor-img">
                      <img src={AdvisorImg} alt={`Jane Bloggs`} />
                    </div>
                    <div className="advisor-contact">
                      <span className="advisor-name">Speak to us today</span>
                      {/* <span className="advisor-designation">{props.People.Designation}</span> */}
                      <a href={`tel:0800 093 2274`} className="phone-number">
                        <i className="icon-phone"></i>
                        <span>0800 093 2274</span>
                      </a>
                    </div>
                  </div>
                </div>
              }

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Advisors